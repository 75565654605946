<script setup lang="ts">
  import FormDropdown from '@/components/FormDropdown.vue'

  import { useIdentityStore } from '@/stores/identity'

  const IdentityStore = useIdentityStore()
</script>

<template>
  <FormDropdown
    v-if="IdentityStore.isAuthenticated"
    :modelValue="IdentityStore.accountId"
    :options="IdentityStore.accounts"
    optionLabel="name"
    optionValue="id"
    placeholder="Select an Account"
    class="w-[10rem] items-center text-center"
    aria-label="Account Selection"
    @update:modelValue="IdentityStore.accountId = $event"
  >
    <!-- TODO: Use PT to style. -->
  </FormDropdown>
</template>

<style lang="postcss" scoped></style>
