import type {
  Paper,
  PaperType,
  Printer,
  PrinterInsert,
  PrinterPatch,
} from '@/entities/printer/types/printer'
import type { Server } from '@/entities/server/types/server'
import type {
  EntityServiceResponse,
  EntityServiceResponseError,
} from '@/services/blackfisk'
import type { CacheAxiosResponse } from 'axios-cache-interceptor'

import { createClient } from '@/services/blackfisk/client'

// # Paper
export interface PaperGraph extends Paper {
  type: PaperType
}
export interface PaperListResponse extends CacheAxiosResponse {
  data: PaperGraph[]
}
export async function paperList (forceRefresh = false) {
  const BlackfiskClient = createClient()
  const ClientResponse: PaperListResponse = await BlackfiskClient({
    method: 'get',
    url: '/blackfisk/warehouse/printers/paper',
    params: {
      ...(forceRefresh && { ts: +new Date() }),
    },
  })
  // TODO: Handle Errors
  const errors: EntityServiceResponseError[] = []
  const { data: papers, cached } = ClientResponse
  return {
    errors,
    papers,
    cached,
  }
}

// # Printer
export interface PrinterGraph extends Printer {
  paper: PaperGraph
}
export interface ServerGraph extends Server {
  printers: PrinterGraph[]
}
export interface PrinterListResponse extends CacheAxiosResponse {
  data: ServerGraph[]
}
export async function printerList (forceRefresh = false) {
  const BlackfiskClient = createClient()
  const ClientResponse: PrinterListResponse = await BlackfiskClient({
    method: 'get',
    url: '/blackfisk/warehouse/printers',
    params: {
      ...(forceRefresh && { ts: +new Date() }),
    },
  })
  // TODO: Handle Errors
  const errors: EntityServiceResponseError[] = []
  const { data: servers, cached } = ClientResponse
  return {
    errors,
    servers,
    cached,
  }
}

export const printerPatchBulk = async (
  patches: PrinterPatch[]
): Promise<EntityServiceResponse> => {
  let errors: EntityServiceResponseError[] = []
  if (patches.length > 0) {
    const BlackfiskClient = createClient()
    try {
      const ClientResponse = await BlackfiskClient({
        method: 'PATCH',
        url: '/v2/blackfisk/printer/bulk',
        data: {
          patches,
        },
      })
      const error = ClientResponse?.data.error ?? ''
      const { errors: patchErrors } = ClientResponse?.data?.[0] ?? {
        errors: [error],
      }
      // console.log({error, errors})
      errors = [...patchErrors]
    } catch (err) {
      console.error(err)
      errors.push({
        message: 'Something went wrong patching printers.',
        code: null,
        reference: null,
      })
    }
  }
  return {
    errors,
  }
}
export const printerInsertBulk = async (
  inserts: PrinterInsert[]
): Promise<EntityServiceResponse> => {
  let errors: EntityServiceResponseError[] = []
  if (inserts.length > 0) {
    const BlackfiskClient = createClient()
    try {
      const ClientResponse = await BlackfiskClient({
        method: 'PUT',
        url: '/v2/blackfisk/printer/bulk',
        data: {
          inserts,
        },
      })
      const error = ClientResponse?.data.error ?? ''
      const { errors: insertErrors } = ClientResponse?.data?.[0] ?? {
        errors: [error],
      }
      errors = [...insertErrors]
    } catch (err) {
      console.error(err)
      errors.push({
        message: 'Something went wrong inserting printers.',
        code: null,
        reference: null,
      })
    }
  }
  return {
    errors,
  }
}
export const printProductBarcodeLabel = async (
  serverPrinterId: number,
  barcode: string,
  sku: string,
  title: string
): Promise<boolean> => {
  const BlackfiskClient = createClient()
  try {
    await BlackfiskClient({
      method: 'POST',
      url: '/v1/blackfisk/print/printProductPackageLabel',
      data: {
        serverPrinterId,
        barcode,
        labelSku: sku,
        labelTitle: title,
      },
    })
  } catch (err) {
    console.error(err)
    return false
  }
  return true
}
