<script setup lang="ts">
  import { useSentry } from './composables/useSentry'

  import { RouterView } from 'vue-router'

  import AppSettingsButton from '@/components/AppSettingsButton.vue'
  import MainNavigation from '@/components/MainNavigation.vue'
  import MemoryUsage from '@/components/MemoryUsage.vue'
  import DynamicDialog from 'primevue/dynamicdialog'
  import Toast from 'primevue/toast'
  import { usePrinters } from '@/composables/usePrinters'

  import { useIdentityStore } from '@/stores/identity'

  const { initializeSentryUser } = useSentry()
  initializeSentryUser()

  const IdentityStore = useIdentityStore()

  const { refreshPrintersAndPaper } = usePrinters()
  if (IdentityStore.isAuthenticated) {
    refreshPrintersAndPaper()
  }

  const environment = import.meta.env.VITE_ENVIRONMENT
</script>

<template>
  <div class="bf-background-dark bf-text flex min-h-screen flex-col">
    <DynamicDialog />
    <div class="flex flex-1 flex-col">
      <header class="w-full">
        <MainNavigation />
        <!-- <nav class="flex flex-start flex-wrap w-full text-base text-center my-2 bf-text-dark">
          <RouterLink to="/">Home</RouterLink>
          <RouterLink to="/about">About</RouterLink>
        </nav> -->
      </header>
      <Toast position="bottom-right" group="br" class="!z-[1200]" />
      <Toast position="bottom-center" group="bc" class="!z-[1200]" />
      <MemoryUsage v-if="environment === 'local'" />
      <RouterView :key="IdentityStore.accountId" />
    </div>
    <AppSettingsButton />
  </div>
</template>

<style lang="postcss" scoped></style>
