import router from '@/router'

import { useIdentityStore } from '@/stores/identity'

export default {
  install () {
    const IdentityStore = useIdentityStore()
    router.beforeEach((to) => {
      const hasAuthentication = to.meta.requiresAuth
        ? IdentityStore.isAuthenticated
        : true
      const hasRoleBasedAccess = IdentityStore.hasPermission({
        requiredRoles: to.meta.requiredRoles,
        requiredGroups: to.meta.requiredGroups,
      })
      if (!(hasAuthentication && hasRoleBasedAccess)) {
        IdentityStore.returnUrl = to.fullPath
        return {
          name: 'login',
        }
      }
    })
  },
}
