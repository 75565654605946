import * as Sentry from '@sentry/vue'
import { useRouter } from 'vue-router'
import { watch } from 'vue'
import { storeToRefs } from 'pinia'

import { useIdentityStore } from '@/stores/identity'

export const useSentry = () => {
  const initializeSentryUser = () => {
    const router = useRouter()
    const IdentityStore = useIdentityStore()
    const { userId, userEmail } = storeToRefs(IdentityStore)
    // * Set/Unset User on IdentityStore userId change
    watch(userId, () => {
      if (userId.value > 0) {
        Sentry.setUser({
          id: userId.value,
          email: userEmail.value,
        })
        Sentry.addBreadcrumb({
          category: 'auth',
          message: 'Authenticated user ' + userEmail.value,
          level: 'info',
        })
      } else {
        Sentry.setUser(null)
        Sentry.addBreadcrumb({
          category: 'auth',
          message: 'Logged out user',
          level: 'info',
        })
      }
    })
    // * Set/Unset User on Route Navigation to maintain user context during lazy loaded route change.
    router.afterEach(() => {
      if (userId.value > 0) {
        Sentry.setUser({
          id: userId.value,
          email: userEmail.value,
        })
      } else {
        Sentry.setUser(null)
      }
    })
  }
  return {
    Sentry,
    initializeSentryUser,
  }
}
