import type { User } from '@/entities/user'

import { createClient } from '@/services/blackfisk/client'

export interface UserRole {
  id: number
  role: string
  roleTypeId: number
  modifiedAt: string
  modifiedBy: number
  createdAt: string
  createdBy: number
  description: string
}
export interface UserAccount {
  id: number
  name: string
  ianaTimeZoneString: string
}
export interface LoggedInUser extends User {
  roles: UserRole[]
  accounts: UserAccount[]
  token: string
}

export async function authLogin ({ email = '', password = '' } = {}) {
  if (!email || !password) {
    throw new Error('Login requires an email and password.')
  }
  const BlackfiskClient = createClient()
  const ClientResponse = await BlackfiskClient({
    method: 'POST',
    url: '/v1/blackfisk/user/login',
    data: {
      email,
      password,
    },
  })
  // TODO: Handle Errors
  const errors: unknown[] = []
  const user: LoggedInUser =
    ClientResponse.data.length > 0 ? ClientResponse.data[0] : null
  return {
    errors,
    user,
  }
}
