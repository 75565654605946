import '@/assets/main.css'

import App from './App.vue'
import routeAuthentication from './plugins/routeAuthentication'
import router from './router'

import * as Sentry from '@sentry/vue'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createApp } from 'vue'
import { createPinia } from 'pinia'

import PrimeVue from 'primevue/config'
import ConfirmationService from 'primevue/confirmationservice'
import DialogService from 'primevue/dialogservice'
import ToastService from 'primevue/toastservice'
import Tooltip from 'primevue/tooltip'

// @ts-expect-error Presets from primevue
import Aura from '@/assets/presets/aura'

const app = createApp(App)
app.provide('VUE_APP_V2_BASE_URL', import.meta.env.VITE_BFSK_APP_V2_BASE_URL)

app.use(PrimeVue, {
  unstyled: true,
  pt: Aura,
  zIndex: {
    modal: 1100, // dialog,sidebar
    overlay: 1000, // dropdown, overlaypanel
    menu: 1000, // overlay menus
    tooltip: 1100, // tooltip
  },
})
app.use(ToastService)
app.use(ConfirmationService)
app.use(DialogService)
app.directive('tooltip', Tooltip)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(pinia)
app.use(routeAuthentication)
app.use(router)

const reportException = (err: Error | unknown) => {
  if (typeof err === 'string') {
    Sentry.captureMessage(err)
  } else if (err instanceof Error) {
    Sentry.captureException(err)
  } else {
    Sentry.captureException(err)
  }
}

// ! Router Error Handling
router.onError((err) => {
  console.error('Router error', err)
  reportException(err)
})

// ! Global Error Handler
app.config.errorHandler = (err, instance, info) => {
  console.error('Global Error', err)
  console.log('Vue Instance', instance)
  console.log('Error info', info)
  reportException(err)
}

// ! Sentry
Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_ENV,
  integrations: [
    Sentry.browserTracingIntegration(),
    // Sentry.replayIntegration({
    //   maskAllText: false,
    //   blockAllMedia: false,
    // }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/app-v3\.next\.blackfisk\.com\/api/,
    /^https:\/\/api\.next\.blackfisk\.com/,
  ],
  // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // beforeSend(event) {
  //   // Modify or drop the event here
  //   return event;
  // },
})

app.mount('#app')
