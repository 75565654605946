import { namedRoutes } from '@/router/routes'

import { useIdentityStore } from '@/stores/identity'

export const isRouteVisible = (routeName: string) => {
  const IdentityStore = useIdentityStore()
  if (
    namedRoutes[routeName]?.meta?.requiresAuth &&
    !IdentityStore.isAuthenticated
  ) {
    return false
  }
  return IdentityStore.hasPermission({
    requiredRoles: namedRoutes[routeName]?.meta?.requiredRoles ?? [],
    requiredGroups: namedRoutes[routeName]?.meta?.requiredGroups ?? [],
  })
}
