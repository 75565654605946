<script lang="ts" setup>
  import { useMemory } from '@vueuse/core'

  function size (v: number) {
    const kb = v / 1024 / 1024
    return `${kb.toFixed(2)} MB`
  }
  const { isSupported, memory } = useMemory()
</script>

<template>
  <div
    v-if="isSupported && memory"
    class="bg-surface-50 p-4 dark:bg-primary-700 dark:text-surface-750"
  >
    <div class="flex justify-between">
      <template v-if="memory">
        <div class="flex items-center gap-3">
          <div class="rounded-md bg-primary-700 p-2 dark:bg-surface-750">
            <i
              class="pi pi-wrench text-2xl text-surface-900 dark:text-primary-700"
            ></i>
          </div>
          <div class="">
            <div class="text-xl font-bold">Memory Usage</div>
            <div>Development</div>
          </div>
        </div>
        <div>
          <div opacity="50" class="font-bold">Used</div>
          <div class="text-xl">{{ size(memory.usedJSHeapSize) }}</div>
        </div>
        <div>
          <div opacity="50" class="font-bold">Allocated</div>
          <div class="text-xl">{{ size(memory.totalJSHeapSize) }}</div>
        </div>
        <div>
          <div opacity="50" class="font-bold">Limit</div>
          <div class="text-xl">{{ size(memory.jsHeapSizeLimit) }}</div>
        </div>
        <div></div>
      </template>
    </div>
  </div>
  <div v-else>Your browser does not support performance memory API</div>
</template>
