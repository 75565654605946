import type { RouteRecordRaw } from 'vue-router'

import HomeView from '@/views/HomeView.vue'

export const namedRoutes: Record<string, RouteRecordRaw> = {
  'home': {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: false, requiredRoles: [], requiredGroups: [] },
  },
  'about': {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    meta: { requiresAuth: false, requiredRoles: [], requiredGroups: [] },
  },
  'login': {
    path: '/login',
    name: 'login',
    component: () => import('../views/AppLogin.vue'),
    meta: { requiresAuth: false, requiredRoles: [], requiredGroups: [] },
  },
  'user-settings': {
    path: '/user-settings',
    name: 'user-settings',
    component: () => import('../views/UserSettings.vue'),
    meta: {
      requiresAuth: true,
      requiredRoles: [],
      requiredGroups: ['adminUserAccess'],
    },
  },
  'product-manager': {
    path: '/product/manage',
    name: 'product-manager',
    component: () => import('../views/ProductManager.vue'),
    meta: { requiresAuth: true, requiredRoles: [], requiredGroups: [] },
  },
  'promotion-manager': {
    path: '/promotion/manage',
    name: 'promotion-manager',
    component: () => import('../views/PromotionManager.vue'),
    meta: { requiresAuth: true, requiredRoles: [], requiredGroups: [] },
  },
  'promotion-editor': {
    path: '/promotion/:id',
    name: 'promotion-editor',
    component: () => import('../views/PromotionEditor.vue'),
    meta: { requiresAuth: true, requiredRoles: [], requiredGroups: [] },
  },
  'amazon-competitive-reporting': {
    path: '/amazon/competitive/reporting',
    name: 'amazon-competitive-reporting',
    component: () => import('../views/AmazonCompetitiveReporting.vue'),
    meta: {
      requiresAuth: true,
      requiredRoles: [],
      requiredGroups: ['adminUserAccess'],
    },
  },
}
export const routes = Object.values(namedRoutes)
