<script setup lang="ts">
  import { ref, useId, watch } from 'vue'

  import FormInputSwitch from '@/components/FormInputSwitch.vue'
  import InputGroup from 'primevue/inputgroup'

  import { useThemeStore } from '@/stores/theme'

  const ThemeStore = useThemeStore()
  const themeToggle = ref(ThemeStore.theme === 'light')
  watch(themeToggle, () => {
    if (themeToggle.value) {
      ThemeStore.themeSet('light')
    } else {
      ThemeStore.themeSet('dark')
    }
  })
</script>

<template>
  <InputGroup class="flex gap-2 px-2">
    <i class="pi pi-moon text-md text-primary m-auto dark:text-white" />
    <FormInputSwitch
      v-model="themeToggle"
      class="m-auto px-6 py-2"
      :inputId="`toggle-theme-mode-${useId()}`"
    />
    <i class="pi pi-sun text-md text-primary m-auto dark:text-white" />
  </InputGroup>
</template>

<style lang="postcss" scoped></style>
