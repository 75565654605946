<script setup lang="ts">
  import type { Ref } from 'vue'

  import { defineAsyncComponent, shallowRef } from 'vue'

  const PrinterConfiguration = defineAsyncComponent(
    () => import('@/components/PrinterConfiguration.vue')
  )
  export interface AppSettingMenuItem {
    component: ReturnType<typeof defineAsyncComponent>
    title: string
    icon: string
  }
  const AppSettings: AppSettingMenuItem[] = [
    {
      component: PrinterConfiguration,
      title: 'Printer Settings',
      icon: 'pi pi-print text-primary-500',
    },
  ]
  const activeSettingComponent: Ref<ReturnType<typeof defineAsyncComponent>> =
    shallowRef(PrinterConfiguration)
  const onSelectAppSetting = (AppSetting: AppSettingMenuItem) => {
    activeSettingComponent.value = AppSetting.component
  }
</script>

<template>
  <div class="app-settings flex flex-col md:flex-row">
    <div
      class="settings-menu mb-4 w-full border-r-2 border-surface-100 pr-0 dark:border-surface-850 md:mb-0 md:w-2/12 md:pr-8"
    >
      <ul>
        <li
          v-for="AppSetting in AppSettings"
          :key="AppSetting.title"
          class="mb-2 flex cursor-pointer items-center rounded p-2 pl-4 shadow"
          :class="{
            'ring ring-inset ring-surface-200 dark:ring-surface-700':
              AppSetting.component !== activeSettingComponent,
            'bg-surface-700 text-white dark:bg-surface-700':
              AppSetting.component === activeSettingComponent,
          }"
          @click="onSelectAppSetting(AppSetting)"
        >
          <i :class="AppSetting.icon" />
          <span class="pl-2">{{ AppSetting.title }}</span>
        </li>
      </ul>
    </div>
    <div class="settings-content w-full px-0 md:px-8">
      <Component :is="activeSettingComponent"></Component>
    </div>
  </div>
</template>

<style lang="postcss"></style>
